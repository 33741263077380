.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: baseline;
  border-top: 1px solid #dee2e6;
  font-size: 20px;
}

.table-container {
  overflow-x: auto;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.button--confined {
  background-color: #ff4d4d;
  color: #fff;
  padding: 0.25rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.button--confined:hover {
  background-color: #e60000;
}


.link__btn {
  color: inherit;
  text-decoration: none;
}

.buy__btn:hover {
  background-color: #e60000;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-4 {
  font-size: 1.5rem !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.w-25 {
  width: 25% !important;
}

.display-3 {
  font-size: 1.75rem !important;
  font-weight: 400 !important;
  line-height: 1.2 !important;
}

.form-group {
  margin-bottom: 1rem !important;
}

.form-control-lg {
  padding: 0.5rem 1rem !important;
  font-size: 1.25rem !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
}

.form-control-lg:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #ff4d4d !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(255, 77, 77, 0.25) !important;
}

.form-control-lg::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control-lg:-ms-input-placeholder {
  color: #6c757d;
}

.form-control-lg::-ms-input-placeholder {
  color: #6c757d;
}

.form-control-lg::placeholder {
  color: #6c757d;
  opacity: 1;
}

.text-danger {
  color: #dc3545 !important;
}

.summary-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 768px) {
  .summary-section {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .summary-section h6 {
    text-align: center;
  }

  .summary-section h6 > span {
margin-left: 0.5rem;
  }
}