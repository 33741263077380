.service__item {
    padding: 20px;
    display: flex;
    align-items: center;
    column-gap: 0.7rem;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    background: var(--card-bg-01);
    height: 10rem;
}

.service__item:hover {
    transform: scale(1.1);
}

.service__item span i {
    font-size: 2.2rem;
    background: var(--primary-color);
    padding: 10px;
    border-radius: 50px;
    color: #fff;
    font-weight: 400 !important;
}

.service__item h3 {
    color: var(--font-primary-color);
    font-size: 1.1rem;
    font-weight: 600;
}

.service__item p {
    font-size: 0.9rem;
    margin-top: 5px;
    color: var(--font-secondary-color);
}

@media only screen and (max-width: 992px) {
    .service__item span i {
        font-size: 1.8rem;
        padding: 5px;
    }

    .service__item h3 {
        font-size: 1rem;
    }

    .service__item {
        padding: 10px;
        margin-bottom: 1.5rem;
    }
}

@media only screen and (max-width: 768px) {
    .service__item span i {
        font-size: 1.8rem;
        padding: 5px;
    }
}

@media only screen and (max-width: 576px) {
    .service__item {
        padding: 20px;
        height:fit-content;
    }
}