.product-card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  margin-top: 1rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  height: fit-content;
  width: 100%;
}

.product-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
}

.product-card__link {
  text-decoration: none;
  color: inherit;
}

.product-card__image {
  width: 100%;
  height: 180px;
  border-radius: 8px;
  overflow: hidden;
}

.product-card__image-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-card__details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
  margin-top: 1rem;
}

.product-card__name {
  font-size: 1.2rem;
  color: #222;
  font-weight: 600;
  margin-top: 0.5rem;
}

.product-card__category {
  font-size: 0.9rem;
  color: #888;
  margin-top: 0.2rem;
}

.product-card__description {
  font-size: 0.9rem;
  color: #555;
  margin-top: 0.5rem;
  height: 3rem;
}

.product-card__rating {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: #ffa500;
}

.product-card__reviews {
  font-size: 0.9rem;
  color: #888;
  margin-left: 0.5rem;
}

.product-card__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.product-card__icons {
  display: flex;
}

.product-card__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #f0f0f0;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.product-card__icon:hover {
  background-color: #e0e0e0;
}

.product-card__icon--wishlist {
  color: #ff5e57;
}

.product-card__icon--add-to-cart {
  color: #00b894;
}

.product-card__price {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--font-primary-color);
}

@media (max-width: 768px) {
  .product-card {
    height: auto;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;
  }

  .product-card>.product-card__link {
    width: 40%;
  }

  .product-card__image {
    width: 100%;
    height: auto;
    margin-bottom: 0;
    border-radius: 4px 4px 4px 4px;
    align-content: center;
  }


  .product-card__details {
    width: 60%;
    padding-left: 1rem;
    margin-top: 0;
    width: 60%;
  }

  .product-card__name {
    font-size: 1rem;
  }

  .product-card__description {
    font-size: 0.8rem;
    height: 2.5rem;
    height: fit-content;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .product-card__rating {
    margin-top: 0.3rem;
  }

  .product-card__reviews {
    font-size: 0.8rem;
    margin-left: 0.3rem;
  }

  .product-card__actions {
    margin-top: 0.1rem;
  }

  .product-card__icon {
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 0.3rem;
  }

  .product-card__price {
    font-size: 1.1rem;
  }
}