.table tr td img {
  width: 80px !important;
  height: 80px !important;
  object-fit: cover;
}

.cart-section {
  padding: 20px;
  background-color: #f8f8f8;
}

.cart-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.table th {
  text-align: left;
  font-weight: bold;
  color: #555;
}

.table td {
  vertical-align: middle !important;
}

.product-image {
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin-right: 10px;
}


.product-info {
  display: flex;
  align-items: center;
}

.product-title {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.product-price {
  font-size: 14px;
  color: #777;
}

.quantity-input {
  width: 60px;
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  color: #333;
}

.delete-icon {
  color: #999;
  cursor: pointer;
}

.summary-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.summary-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.summary-price {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.cart-buttons {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

td i {
  cursor: pointer;
  color: red;
}

@media only screen and (max-width: 768px) {
  .table tr td img {
    width: 60px !important;
    height: 60px !important;
  }

  .cart-section {
    padding: 10px;
  }

  .summary-section {
    padding: 10px;
  }

  .cart-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .buy__btn {
    margin-top: 10px !important;
    margin-left: 0rem !important;
  }
}