@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
/* google fonts */

/*  css variables */
:root {
  --card-bg-01: #fdefe6;
  --card-bg-02: #d6e5fb;
  --card-bg-03: #ceebe9;
  --card-bg-04: #e2f2b2;
  --primary-color: #BBBA3A;
  --font-primary-color: #5f2a75;
  --font-secondary-color: #341547;
  --btn-color: #e34870;
  --hero-bg: #F3F2DA;
  --heading-fontSize: 2rem;
  --small-text-color: #999;
  --heading-text-color: #0a1d37;
}

/* reset browser default */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

p {
  color: #999;
  color: var(--small-text-color);
  font-size: 1rem;
}

h1,
h2 {
  font-size: 2rem;
  font-size: var(--heading-fontSize);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: unset;
}

img {
  width: 100%;
}

section {
  padding: 60px 0px;
}

.section__title {
  color: #5f2a75;
  color: var(--font-primary-color);
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  section {
    padding: 40px 0px;
  }
}

.header {
  width: 100%;
  height: 70px;
  line-height: 70px;
  background-color: var(--primary-color);
}

.nav__wrapper {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
  -webkit-columns: 8px;
          columns: 8px;
  flex-direction: row;
}

.logo img {
  width: 4rem;
  height: 4rem;
}

.logo h1 {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--font-secondary-color);
}

.logo:hover {
  cursor: pointer;
}

.menu {
  display: flex;
  align-items: center;
  grid-column-gap: 2.7rem;
  -webkit-column-gap: 2.7rem;
          column-gap: 2.7rem;
  margin-bottom: 0;
  list-style: none;
}

.nav__item a {
  color: var(--font-secondary-color);
  font-weight: 500;
  cursor: pointer;
}

.nav__icons {
  display: flex;
  align-items: center;
  grid-column-gap: 1.2rem;
  -webkit-column-gap: 1.2rem;
          column-gap: 1.2rem;
}

.nav__icons span i {
  font-size: 1.4rem;
  color: var(--font-secondary-color);
  cursor: pointer;
}

.cart__icon,
.fav__icon {
  position: relative;
}

.badge__header {
  position: absolute;
  top: -80%;
  right: -25%;
  width: 15px;
  height: 15px;
  content: "";
  background: var(--btn-color);
  color: #fff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  font-weight: 600;
  z-index: 10;
}

.nav__icons span img {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
}

.mobile__menu {
  font-size: 1.3rem;
  color: var(--font-secondary-color);
  display: none;
}

.nav__active {
  font-weight: 700 !important;
}

.sticky__header {
  width: 100%;
  height: 70px;
  line-height: 70px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  box-shadow: 3px 3px 8px -3px #ddd;
  background-color: var(--primary-color);
}

.profile {
  position: relative;
}

.dropdown__container {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  margin-top: 4px;
  padding: 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.dropdown__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdown__list li {
  padding: 3px 5px;
  cursor: pointer;
}

.dropdown__list li:hover {
  background-color: #f5f5f5;
}

.dropdown__list li:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.dropdown__list li:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.login-signup {
  display: flex;
  align-items: center;
}

.login-signup a {
  margin-left: 10px;
  color: white;
}

@media only screen and (max-width: 768px) {
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000db;
    z-index: 9999;
    display: none;
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #fff;
    z-index: 99999;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .active__menu {
    display: block;
  }

  .mobile__menu {
    display: block;
  }

  .mobile__menu span i {
    font-style: 1.1rem !important;
  }

  .nav__wrapper {
    padding-left: 0px;
  }

  .logo img {
    width: 3rem;
    height: 3rem;
  }

  .logo h1 {
    font-size: 1rem;
  }

  .fav__icon i,
  .cart__icon i {
    font-size: 1.2rem !important;
  }

  .nav__icons span img {
    width: 1.4rem;
    height: 1.4rem;
  }

  .header {
    width: 100%;
    height: 60px;
    line-height: 60px;
  }
}
.language-selector {
    display: flex;
    align-items: center;
}

.header-language {
    cursor: pointer;
    color: var(--font-secondary-color);
    font-size: 1rem !important; 
}

.header-language:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
}
.table tr td img {
  width: 80px !important;
  height: 80px !important;
  object-fit: cover;
}

.cart-section {
  padding: 20px;
  background-color: #f8f8f8;
}

.cart-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.table th {
  text-align: left;
  font-weight: bold;
  color: #555;
}

.table td {
  vertical-align: middle !important;
}

.product-image {
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin-right: 10px;
}


.product-info {
  display: flex;
  align-items: center;
}

.product-title {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.product-price {
  font-size: 14px;
  color: #777;
}

.quantity-input {
  width: 60px;
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  color: #333;
}

.delete-icon {
  color: #999;
  cursor: pointer;
}

.summary-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.summary-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.summary-price {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.cart-buttons {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

td i {
  cursor: pointer;
  color: red;
}

@media only screen and (max-width: 768px) {
  .table tr td img {
    width: 60px !important;
    height: 60px !important;
  }

  .cart-section {
    padding: 10px;
  }

  .summary-section {
    padding: 10px;
  }

  .cart-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .buy__btn {
    margin-top: 10px !important;
    margin-left: 0rem !important;
  }
}
.common__section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px !important;
  transition: background-color 0.5s ease-in-out;
}

.common__section h1 {
  margin: 0;
  transition: color 0.5s ease-in-out;
}

.prev-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.5s ease-in-out;
}

.carousel-arrows {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
}

.carousel-arrow-left,
.carousel-arrow-right {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #fff;
  transition: color 0.3s ease-in-out;
}

.carousel-arrow-left:hover,
.carousel-arrow-right:hover {
  color: #ff5e63;
}

@media only screen and (max-width: 768px) {
  .common__section {
    height: 250px !important;
  }

  .common__section h1 {
    font-size: 1rem !important;
  }

  .carousel-arrows {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .carousel-arrow-left,
  .carousel-arrow-right {
    font-size: 15px;
  }
}
.hero__section {
  background: var(--primary-color);
}

.hero__subtitle {
  font-size: 1.5rem;
}

.hero__content h2 {
  color: var(--font-primary-color);
  font-size: 2.8rem;
  font-weight: 600;
  margin: 20px 0px;
}

.hero__content p {
  color: var(--font-secondary-color);
  line-height: 28px;
}

.hero__section p:nth-child(1) {
  font-weight: 500;
}

.buy__btn {
  border: none;
  outline: none;
  padding: 8px 20px;
  border-radius: 5px;
  background: var(--btn-color);
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 40px;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.buy__btn:hover,
.buy__btn:active {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.hero__content {
  padding-top: 45px;
  -webkit-animation: slide-up 1s ease;
          animation: slide-up 1s ease;
}

.timer__count {
  background: var(--primary-color);
  height: 300px;
}

.timer__count img {
  width: 70% !important;
  height: 70% !important;
  object-fit: contain;
}

.store__btn {
  background: var(--btn-color);
  color: #fff;
  font-weight: 600;
}

.store__btn a:hover {
  color: var(--primary-color);
}

.clock__top-content {
  color: var(--font-primary-color);
}

.hero__img {
  width: 20rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  -webkit-animation: slide-down 1s ease;
          animation: slide-down 1s ease;
}

.trending__products,
.best__sales,
.new__arrivals,
.popular__category {
  padding: 30px 0px;
}

@media only screen and (max-width: 992px) {
  .hero__content h2 {
    font-size: 2rem;
  }

  .section__title {
    font-size: 1.7rem;
  }

  .counter__img {
    display: none;
  }

  .clock__top-content {
    text-align: center;
  }

  .clock__top-content h4 {
    font-size: 0.9rem !important;
  }

  .clock__top-content h3 {
    font-size: 1rem !important;
  }

  .store__btn {
    margin-left: 18rem;
  }

  .count__down-col {
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {

  .hero__content h2 {
    font-style: 1.6rem;
  }

  .hero__content p {
    font-size: 0.9rem;
  }

  .buy__btn {
    font-size: 0.9rem;
  }

  .section__title {
    font-size: 1.4rem;
  }

  .store__btn {
    margin-left: 13rem;
  }

}

@media only screen and (max-width: 576px) {
  .buy__btn {
    margin-left: 30%;
    margin-top: 2rem;
  }

  .hero__content {
    padding-top: 0;
    margin-bottom: 30px;
  }

  .store__btn {
    justify-items: center;
  }

  .hero__section {
    padding-top: 20px;
    padding-bottom: 0px;
  }

  .hero__img {
    display: none;
  }
}
.service__item {
    padding: 20px;
    display: flex;
    align-items: center;
    grid-column-gap: 0.7rem;
    -webkit-column-gap: 0.7rem;
            column-gap: 0.7rem;
    border-radius: 5px;
    cursor: pointer;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    background: var(--card-bg-01);
    height: 10rem;
}

.service__item:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.service__item span i {
    font-size: 2.2rem;
    background: var(--primary-color);
    padding: 10px;
    border-radius: 50px;
    color: #fff;
    font-weight: 400 !important;
}

.service__item h3 {
    color: var(--font-primary-color);
    font-size: 1.1rem;
    font-weight: 600;
}

.service__item p {
    font-size: 0.9rem;
    margin-top: 5px;
    color: var(--font-secondary-color);
}

@media only screen and (max-width: 992px) {
    .service__item span i {
        font-size: 1.8rem;
        padding: 5px;
    }

    .service__item h3 {
        font-size: 1rem;
    }

    .service__item {
        padding: 10px;
        margin-bottom: 1.5rem;
    }
}

@media only screen and (max-width: 768px) {
    .service__item span i {
        font-size: 1.8rem;
        padding: 5px;
    }
}

@media only screen and (max-width: 576px) {
    .service__item {
        padding: 20px;
        height:-webkit-fit-content;
        height:-moz-fit-content;
        height:fit-content;
    }
}
.product-card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  margin-top: 1rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
}

.product-card:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
}

.product-card__link {
  text-decoration: none;
  color: inherit;
}

.product-card__image {
  width: 100%;
  height: 180px;
  border-radius: 8px;
  overflow: hidden;
}

.product-card__image-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-card__details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
  margin-top: 1rem;
}

.product-card__name {
  font-size: 1.2rem;
  color: #222;
  font-weight: 600;
  margin-top: 0.5rem;
}

.product-card__category {
  font-size: 0.9rem;
  color: #888;
  margin-top: 0.2rem;
}

.product-card__description {
  font-size: 0.9rem;
  color: #555;
  margin-top: 0.5rem;
  height: 3rem;
}

.product-card__rating {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: #ffa500;
}

.product-card__reviews {
  font-size: 0.9rem;
  color: #888;
  margin-left: 0.5rem;
}

.product-card__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.product-card__icons {
  display: flex;
}

.product-card__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #f0f0f0;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.product-card__icon:hover {
  background-color: #e0e0e0;
}

.product-card__icon--wishlist {
  color: #ff5e57;
}

.product-card__icon--add-to-cart {
  color: #00b894;
}

.product-card__price {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--font-primary-color);
}

@media (max-width: 768px) {
  .product-card {
    height: auto;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;
  }

  .product-card>.product-card__link {
    width: 40%;
  }

  .product-card__image {
    width: 100%;
    height: auto;
    margin-bottom: 0;
    border-radius: 4px 4px 4px 4px;
    align-content: center;
  }


  .product-card__details {
    width: 60%;
    padding-left: 1rem;
    margin-top: 0;
    width: 60%;
  }

  .product-card__name {
    font-size: 1rem;
  }

  .product-card__description {
    font-size: 0.8rem;
    height: 2.5rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .product-card__rating {
    margin-top: 0.3rem;
  }

  .product-card__reviews {
    font-size: 0.8rem;
    margin-left: 0.3rem;
  }

  .product-card__actions {
    margin-top: 0.1rem;
  }

  .product-card__icon {
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 0.3rem;
  }

  .product-card__price {
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 992px) {
    .clock__wrapper {
        justify-content: center;
    }

    .clock__data h1 {
        font-size: 1.1rem !important;
    }

    .clock__data h5 {
        font-size: 0.9rem !important;
    }
}

.clock__wrapper{
    color: var(--font-secondary-color);
}
.auth-header-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-header-logo-img {
  width: 100px;
}

.auth-header-logo-img img {
  align-self: center;
}

.img-register img {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.auth-header-title {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 10px;
  color: #212529;
  align-self: center;
}

.auth-header-subtitle {
  font-size: 0.9rem;
  margin-bottom: 0;
  color: #6c757d;
  align-self: center;
}

.container {
  width: 90%;
  margin: 0 auto;
  padding: 0 20px;
}

.auth {
  min-height: 80vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auth .img {
  -webkit-animation: slide-down 0.5s ease;
          animation: slide-down 0.5s ease;
  width: 40rem;
}

.auth .form {
  width: 35rem;
  padding: 1.5rem;
  -webkit-animation: slide-up 0.5s ease;
          animation: slide-up 0.5s ease;
  margin: 2rem;
}

.auth .form h2 {
  color: var(--color-danger);
  text-align: center;
}

.auth .form form input[type="text"],
.auth .form form input[type="email"],
.auth .form form input[type="password"] {
  display: block;
  font-size: 1.2rem;
  font-weight: 300;
  padding: 1rem;
  margin: 1rem auto;
  width: 100%;
  border: 1px solid #777;
  border-radius: 3px;
  outline: none;
}

.auth .form form .links {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.auth .form form p {
  text-align: center;
  margin: 1rem;
}

.auth .form .register {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.--btn {
  font-size: 1.8rem;
  font-weight: 400;
  padding: 8px 8px;
  margin: 0 5px 1rem 0;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.--btn:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.--btn-block {
  width: 100%;
}

.--btn-primary {
  color: #fff;
  background: var(--primary-color);
}

.error {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #f5c6cb;
  border-radius: 3px;
}

.card {
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.terms-label {
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.terms-label a {
  color: #007bff;
  font-weight: bold;
}

@media screen and (max-width: 700px) {
  .auth .img {
    display: none;
  }

  .auth .form {
    /* Update the width of the form to fit smaller screens */
    width: 100%;
    padding: 1rem;
    margin: 2rem 0;
  }

  .auth-header-title {
    /* Update the font size to be smaller for smaller screens */
    font-size: 1.5rem;
  }

  .auth-header-subtitle {
    /* Update the font size to be smaller for smaller screens */
    font-size: 0.8rem;
  }

  .auth .form form input[type="text"],
  .auth .form form input[type="email"],
  .auth .form form input[type="password"] {
    /* Update the margin to be smaller for smaller screens */
    margin: 0.5rem auto;
  }

  .terms-label {
    /* Update the font size to be smaller for smaller screens */
    font-size: 1.2rem;
  }

}

@-webkit-keyframes slide-up {
  0% {
    -webkit-transform: translateY(-5rem);
            transform: translateY(-5rem);
  }

  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    -webkit-transform: translateY(-5rem);
            transform: translateY(-5rem);
  }

  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes slide-down {
  0% {
    -webkit-transform: translateY(5rem);
            transform: translateY(5rem);
  }

  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    -webkit-transform: translateY(5rem);
            transform: translateY(5rem);
  }

  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.product__details {
  margin-top: 70px;
}

.image-gallery-image {
  object-fit: 'contain';
  height: "400px";
}

.container {
  width: 110%;
}


.product__details h2 {
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.product__rating span i {
  color: coral;
}

.product__rating p span {
  color: coral;
  font-weight: 500;
}

.product__price {
  font-size: 1.3rem;
  font-weight: 500;
}

.wishlist__btn {
  padding: 10px;
}

.tab__wrapper {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
}

.active__tab {
  font-weight: 600;
}

.tab__content p {
  line-height: 30px;
}

.review__wrapper ul li span {
  color: coral;
  font-weight: 600;
}

.review__wrapper ul li p {
  margin-top: 10px;
}

.review__form {
  width: 70%;
  margin: auto;
  margin-top: 50px;
}

.review__form h4 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 30px;
}

.form__group input,
.form__group textarea {
  width: 100%;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 8px 20px;
}

.form__group input:focus,
.form__group textarea:focus {
  outline: none;
}

.form__group {
  margin-bottom: 30px;
}

.form__group span {
  display: flex;
  align-items: center;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  color: coral;
  font-weight: 600;
  cursor: pointer;
}

.related__title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 30px;
}

.category-badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  background-color: var(--font-primary-color);
  margin: 0.3rem;
}

.wish-cart__btn {
  display: flex;
  flex-direction: row;
  width: -webkit-max-content;
  width: max-content;
  grid-gap: 1rem;
  gap: 1rem;
}

.buy__btn:disabled {
  background-color: gray;
}

@media only screen and (max-width: 768px) {
  .product__details h2 {
    font-size: 1.3rem;
  }

  .image-gallery-image {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .rating__group {
    grid-column-gap: 1rem !important;
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }
}
.remove-icon i {
    color: green;
    transition: color 0.2s ease;
  }
  
  .remove-icon:hover i {
    color: red;
  }
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}

.card {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.card:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.card-header {
  padding: 10px;
  background-color: var(--hero-bg)
}

.card-title {
  margin: 0;
  color: var(--font-secondary-color);
}

.card-subtitle {
  margin: 0;
  font-size: 14px;
  color: #777;
}

.card-body {
  padding: 10px;
}

.card-text {
  margin-bottom: 5px;
  color: var(--font-secondary-color);

}

.btn {
  display: block;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  background: var(--btn-color);
  border: none;

}


.btn:hover,
.btn:active {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  background-color: gray;
  color: white;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: baseline;
  border-top: 1px solid #dee2e6;
  font-size: 20px;
}

.table-container {
  overflow-x: auto;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.button--confined {
  background-color: #ff4d4d;
  color: #fff;
  padding: 0.25rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.button--confined:hover {
  background-color: #e60000;
}


.link__btn {
  color: inherit;
  text-decoration: none;
}

.buy__btn:hover {
  background-color: #e60000;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-4 {
  font-size: 1.5rem !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.w-25 {
  width: 25% !important;
}

.display-3 {
  font-size: 1.75rem !important;
  font-weight: 400 !important;
  line-height: 1.2 !important;
}

.form-group {
  margin-bottom: 1rem !important;
}

.form-control-lg {
  padding: 0.5rem 1rem !important;
  font-size: 1.25rem !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
}

.form-control-lg:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #ff4d4d !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(255, 77, 77, 0.25) !important;
}

.form-control-lg:-ms-input-placeholder {
  color: #6c757d;
}

.form-control-lg::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control-lg::placeholder {
  color: #6c757d;
  opacity: 1;
}

.text-danger {
  color: #dc3545 !important;
}

.summary-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 768px) {
  .summary-section {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .summary-section h6 {
    text-align: center;
  }

  .summary-section h6 > span {
margin-left: 0.5rem;
  }
}
.content-container {
    max-width: 110rem;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.terms {
    margin: 0 auto;
}

p {
    color: black;
    margin-bottom: 0.5rem;
}

.title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
}

.subtitle {
    font-weight: bold;
    margin-bottom: 2rem;
}

.content {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #555;
}

.updated {
    margin-top: 4rem;
}

.sub-list{
    margin-left: 1.5rem;
}
.content-container {
  max-width: 90rem;
  margin: 20px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
}


.content-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
.content-container p {
  font-size: 16px;
  line-height: 1.5;
  color: #666;
}

.anchor-tag {
  color: #007bff;
  text-decoration: none;
}

.anchor-tag:hover {
  text-decoration: underline;
}
.footer {
    padding-top: 60px;
    padding-bottom: 30px;
    background: var(--primary-color);
}


.footer-title {
    font-size: 3rem;
    margin: 0;
}

.footer__text {
    line-height: 30px;
    color: #ffffffe0;

}

.footer__quick-links ul li {
    background: transparent !important;
}

.footer__quick-links ul li a {
    color: #ffffffe0;
}

.quick__links-title {
    margin-bottom: 20px;
    color: #fff;
    font-size: 1.1rem;
}

.footer__contact li span,
.footer__contact li p {
    color: #ffffffe0;
}

.footer__contact li span {
    font-size: 1.2rem;
}

.footer__contact li p {
    font-size: 0.9rem;
}

.footer__copyright-text {
    color: #ffffffe0;
    font-size: 0.9rem;
    text-align: center;
    padding-top: 0.8rem;
    padding-bottom: 0.5rem;
}

.footer__copyright {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: center;
}


.footer__quick-links ul li {
    border-color: transparent;
}


.billing__form .form__group input,
.billing__form .form__group select {
  border: 1px solid var(--font-primary-color);
  background-color: white;
  color: var(--font-primary-color);
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.billing__form .form__group select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7.41,8.59L12,13.17l4.59-4.58L18,10l-6,6l-6-6L7.41,8.59z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
  padding-right: 24px;
}

.checkout__cart {
  padding: 20px;
  background: var(--primary-color);
  color: #fff;
  border-radius: 5px;
}

.checkout__cart h6,
h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkout__cart h6 {
  margin-bottom: 20px;
}

.checkout__cart h4 {
  border-top: 1px solid rgba(221, 221, 221, 0.253);
  padding-top: 20px;
}

.checkout-button-disabled{
  background-color: gray;
  color: white;
}

.invalid-form{
  text-align: center;
}
.filter__widget select {
  padding: 8px 20px;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  border-radius: 5px;
  background: var(--primary-color);
  color: #fff;
}

.filter__widget select:focus {
  outline: none !important;
}

.filter__widget select option {
  font-size: 0.9rem;
}

.search__box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding-right: 12px;
  padding-left: 2px;
  cursor: pointer;
}
.search__box input {
  width: 100%;
  border: none;
  outline: none;
  padding: 8px 10px;
}

.search__box span {
  color: var(--primary-color);
}

.pagination {
  justify-content: center;
}

.page-link {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

@media only screen and (max-width: 768px) {
  .filter__section{
    padding: 20px 0;
  }
  .filter__widget {
    margin-bottom: 0px;
  }
  .filter__widget select {
    padding: 7px 20px;
    font-size: 0.9rem;
    width: 50%;
  }
  .container{
    width: 100%;
  }
  .text-end{
    text-align: left !important;
  }

  .pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .page-item {
    margin-bottom: 0.5rem;
  }

  .page-link {
    font-size: 0.9rem;
    padding: 0.3rem 0.5rem;
  }
}


