.content-container {
    max-width: 110rem;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.terms {
    margin: 0 auto;
}

p {
    color: black;
    margin-bottom: 0.5rem;
}

.title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
}

.subtitle {
    font-weight: bold;
    margin-bottom: 2rem;
}

.content {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #555;
}

.updated {
    margin-top: 4rem;
}

.sub-list{
    margin-left: 1.5rem;
}