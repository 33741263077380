.auth-header-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-header-logo-img {
  width: 100px;
}

.auth-header-logo-img img {
  align-self: center;
}

.img-register img {
  transform: scaleX(-1);
}

.auth-header-title {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 10px;
  color: #212529;
  align-self: center;
}

.auth-header-subtitle {
  font-size: 0.9rem;
  margin-bottom: 0;
  color: #6c757d;
  align-self: center;
}

.container {
  width: 90%;
  margin: 0 auto;
  padding: 0 20px;
}

.auth {
  min-height: 80vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auth .img {
  animation: slide-down 0.5s ease;
  width: 40rem;
}

.auth .form {
  width: 35rem;
  padding: 1.5rem;
  animation: slide-up 0.5s ease;
  margin: 2rem;
}

.auth .form h2 {
  color: var(--color-danger);
  text-align: center;
}

.auth .form form input[type="text"],
.auth .form form input[type="email"],
.auth .form form input[type="password"] {
  display: block;
  font-size: 1.2rem;
  font-weight: 300;
  padding: 1rem;
  margin: 1rem auto;
  width: 100%;
  border: 1px solid #777;
  border-radius: 3px;
  outline: none;
}

.auth .form form .links {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.auth .form form p {
  text-align: center;
  margin: 1rem;
}

.auth .form .register {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.--btn {
  font-size: 1.8rem;
  font-weight: 400;
  padding: 8px 8px;
  margin: 0 5px 1rem 0;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.--btn:hover {
  transform: translateY(-2px);
}

.--btn-block {
  width: 100%;
}

.--btn-primary {
  color: #fff;
  background: var(--primary-color);
}

.error {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #f5c6cb;
  border-radius: 3px;
}

.card {
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.terms-label {
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.terms-label a {
  color: #007bff;
  font-weight: bold;
}

@media screen and (max-width: 700px) {
  .auth .img {
    display: none;
  }

  .auth .form {
    /* Update the width of the form to fit smaller screens */
    width: 100%;
    padding: 1rem;
    margin: 2rem 0;
  }

  .auth-header-title {
    /* Update the font size to be smaller for smaller screens */
    font-size: 1.5rem;
  }

  .auth-header-subtitle {
    /* Update the font size to be smaller for smaller screens */
    font-size: 0.8rem;
  }

  .auth .form form input[type="text"],
  .auth .form form input[type="email"],
  .auth .form form input[type="password"] {
    /* Update the margin to be smaller for smaller screens */
    margin: 0.5rem auto;
  }

  .terms-label {
    /* Update the font size to be smaller for smaller screens */
    font-size: 1.2rem;
  }

}

@keyframes slide-up {
  0% {
    transform: translateY(-5rem);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(5rem);
  }

  100% {
    transform: translateY(0);
  }
}