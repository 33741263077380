.footer {
    padding-top: 60px;
    padding-bottom: 30px;
    background: var(--primary-color);
}


.footer-title {
    font-size: 3rem;
    margin: 0;
}

.footer__text {
    line-height: 30px;
    color: #ffffffe0;

}

.footer__quick-links ul li {
    background: transparent !important;
}

.footer__quick-links ul li a {
    color: #ffffffe0;
}

.quick__links-title {
    margin-bottom: 20px;
    color: #fff;
    font-size: 1.1rem;
}

.footer__contact li span,
.footer__contact li p {
    color: #ffffffe0;
}

.footer__contact li span {
    font-size: 1.2rem;
}

.footer__contact li p {
    font-size: 0.9rem;
}

.footer__copyright-text {
    color: #ffffffe0;
    font-size: 0.9rem;
    text-align: center;
    padding-top: 0.8rem;
    padding-bottom: 0.5rem;
}

.footer__copyright {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: center;
}


.footer__quick-links ul li {
    border-color: transparent;
}

