.billing__form .form__group input,
.billing__form .form__group select {
  border: 1px solid var(--font-primary-color);
  background-color: white;
  color: var(--font-primary-color);
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.billing__form .form__group select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7.41,8.59L12,13.17l4.59-4.58L18,10l-6,6l-6-6L7.41,8.59z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
  padding-right: 24px;
}

.checkout__cart {
  padding: 20px;
  background: var(--primary-color);
  color: #fff;
  border-radius: 5px;
}

.checkout__cart h6,
h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkout__cart h6 {
  margin-bottom: 20px;
}

.checkout__cart h4 {
  border-top: 1px solid rgba(221, 221, 221, 0.253);
  padding-top: 20px;
}

.checkout-button-disabled{
  background-color: gray;
  color: white;
}

.invalid-form{
  text-align: center;
}