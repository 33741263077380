.hero__section {
  background: var(--primary-color);
}

.hero__subtitle {
  font-size: 1.5rem;
}

.hero__content h2 {
  color: var(--font-primary-color);
  font-size: 2.8rem;
  font-weight: 600;
  margin: 20px 0px;
}

.hero__content p {
  color: var(--font-secondary-color);
  line-height: 28px;
}

.hero__section p:nth-child(1) {
  font-weight: 500;
}

.buy__btn {
  border: none;
  outline: none;
  padding: 8px 20px;
  border-radius: 5px;
  background: var(--btn-color);
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 40px;
  transition: transform 0.2s ease-in-out;
}

.buy__btn:hover,
.buy__btn:active {
  transform: scale(1.2);
}

.hero__content {
  padding-top: 45px;
  animation: slide-up 1s ease;
}

.timer__count {
  background: var(--primary-color);
  height: 300px;
}

.timer__count img {
  width: 70% !important;
  height: 70% !important;
  object-fit: contain;
}

.store__btn {
  background: var(--btn-color);
  color: #fff;
  font-weight: 600;
}

.store__btn a:hover {
  color: var(--primary-color);
}

.clock__top-content {
  color: var(--font-primary-color);
}

.hero__img {
  width: 20rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  animation: slide-down 1s ease;
}

.trending__products,
.best__sales,
.new__arrivals,
.popular__category {
  padding: 30px 0px;
}

@media only screen and (max-width: 992px) {
  .hero__content h2 {
    font-size: 2rem;
  }

  .section__title {
    font-size: 1.7rem;
  }

  .counter__img {
    display: none;
  }

  .clock__top-content {
    text-align: center;
  }

  .clock__top-content h4 {
    font-size: 0.9rem !important;
  }

  .clock__top-content h3 {
    font-size: 1rem !important;
  }

  .store__btn {
    margin-left: 18rem;
  }

  .count__down-col {
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {

  .hero__content h2 {
    font-style: 1.6rem;
  }

  .hero__content p {
    font-size: 0.9rem;
  }

  .buy__btn {
    font-size: 0.9rem;
  }

  .section__title {
    font-size: 1.4rem;
  }

  .store__btn {
    margin-left: 13rem;
  }

}

@media only screen and (max-width: 576px) {
  .buy__btn {
    margin-left: 30%;
    margin-top: 2rem;
  }

  .hero__content {
    padding-top: 0;
    margin-bottom: 30px;
  }

  .store__btn {
    justify-items: center;
  }

  .hero__section {
    padding-top: 20px;
    padding-bottom: 0px;
  }

  .hero__img {
    display: none;
  }
}