.filter__widget select {
  padding: 8px 20px;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  border-radius: 5px;
  background: var(--primary-color);
  color: #fff;
}

.filter__widget select:focus {
  outline: none !important;
}

.filter__widget select option {
  font-size: 0.9rem;
}

.search__box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding-right: 12px;
  padding-left: 2px;
  cursor: pointer;
}
.search__box input {
  width: 100%;
  border: none;
  outline: none;
  padding: 8px 10px;
}

.search__box span {
  color: var(--primary-color);
}

.pagination {
  justify-content: center;
}

.page-link {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

@media only screen and (max-width: 768px) {
  .filter__section{
    padding: 20px 0;
  }
  .filter__widget {
    margin-bottom: 0px;
  }
  .filter__widget select {
    padding: 7px 20px;
    font-size: 0.9rem;
    width: 50%;
  }
  .container{
    width: 100%;
  }
  .text-end{
    text-align: left !important;
  }

  .pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .page-item {
    margin-bottom: 0.5rem;
  }

  .page-link {
    font-size: 0.9rem;
    padding: 0.3rem 0.5rem;
  }
}
