.header {
  width: 100%;
  height: 70px;
  line-height: 70px;
  background-color: var(--primary-color);
}

.nav__wrapper {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
  columns: 8px;
  flex-direction: row;
}

.logo img {
  width: 4rem;
  height: 4rem;
}

.logo h1 {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--font-secondary-color);
}

.logo:hover {
  cursor: pointer;
}

.menu {
  display: flex;
  align-items: center;
  column-gap: 2.7rem;
  margin-bottom: 0;
  list-style: none;
}

.nav__item a {
  color: var(--font-secondary-color);
  font-weight: 500;
  cursor: pointer;
}

.nav__icons {
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
}

.nav__icons span i {
  font-size: 1.4rem;
  color: var(--font-secondary-color);
  cursor: pointer;
}

.cart__icon,
.fav__icon {
  position: relative;
}

.badge__header {
  position: absolute;
  top: -80%;
  right: -25%;
  width: 15px;
  height: 15px;
  content: "";
  background: var(--btn-color);
  color: #fff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  font-weight: 600;
  z-index: 10;
}

.nav__icons span img {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
}

.mobile__menu {
  font-size: 1.3rem;
  color: var(--font-secondary-color);
  display: none;
}

.nav__active {
  font-weight: 700 !important;
}

.sticky__header {
  width: 100%;
  height: 70px;
  line-height: 70px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  box-shadow: 3px 3px 8px -3px #ddd;
  background-color: var(--primary-color);
}

.profile {
  position: relative;
}

.dropdown__container {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  margin-top: 4px;
  padding: 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  height: fit-content;
}

.dropdown__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdown__list li {
  padding: 3px 5px;
  cursor: pointer;
}

.dropdown__list li:hover {
  background-color: #f5f5f5;
}

.dropdown__list li:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.dropdown__list li:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.login-signup {
  display: flex;
  align-items: center;
}

.login-signup a {
  margin-left: 10px;
  color: white;
}

@media only screen and (max-width: 768px) {
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000db;
    z-index: 9999;
    display: none;
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #fff;
    z-index: 99999;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .active__menu {
    display: block;
  }

  .mobile__menu {
    display: block;
  }

  .mobile__menu span i {
    font-style: 1.1rem !important;
  }

  .nav__wrapper {
    padding-left: 0px;
  }

  .logo img {
    width: 3rem;
    height: 3rem;
  }

  .logo h1 {
    font-size: 1rem;
  }

  .fav__icon i,
  .cart__icon i {
    font-size: 1.2rem !important;
  }

  .nav__icons span img {
    width: 1.4rem;
    height: 1.4rem;
  }

  .header {
    width: 100%;
    height: 60px;
    line-height: 60px;
  }
}