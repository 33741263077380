.language-selector {
    display: flex;
    align-items: center;
}

.header-language {
    cursor: pointer;
    color: var(--font-secondary-color);
    font-size: 1rem !important; 
}

.header-language:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
}