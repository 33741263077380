.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.card {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: scale(1.03);
}

.card-header {
  padding: 10px;
  background-color: var(--hero-bg)
}

.card-title {
  margin: 0;
  color: var(--font-secondary-color);
}

.card-subtitle {
  margin: 0;
  font-size: 14px;
  color: #777;
}

.card-body {
  padding: 10px;
}

.card-text {
  margin-bottom: 5px;
  color: var(--font-secondary-color);

}

.btn {
  display: block;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  transition: transform 0.2s ease-in-out;
  background: var(--btn-color);
  border: none;

}


.btn:hover,
.btn:active {
  transform: scale(1.05);
  background-color: gray;
  color: white;
}