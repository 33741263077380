.common__section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px !important;
  transition: background-color 0.5s ease-in-out;
}

.common__section h1 {
  margin: 0;
  transition: color 0.5s ease-in-out;
}

.prev-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.5s ease-in-out;
}

.carousel-arrows {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
}

.carousel-arrow-left,
.carousel-arrow-right {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #fff;
  transition: color 0.3s ease-in-out;
}

.carousel-arrow-left:hover,
.carousel-arrow-right:hover {
  color: #ff5e63;
}

@media only screen and (max-width: 768px) {
  .common__section {
    height: 250px !important;
  }

  .common__section h1 {
    font-size: 1rem !important;
  }

  .carousel-arrows {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .carousel-arrow-left,
  .carousel-arrow-right {
    font-size: 15px;
  }
}