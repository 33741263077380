.content-container {
  max-width: 90rem;
  margin: 20px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
}


.content-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
.content-container p {
  font-size: 16px;
  line-height: 1.5;
  color: #666;
}

.anchor-tag {
  color: #007bff;
  text-decoration: none;
}

.anchor-tag:hover {
  text-decoration: underline;
}